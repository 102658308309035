/* eslint-disable react/jsx-no-bind */
import { useQuery } from '@tanstack/react-query';
import { Button, Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/authentication/actionCreator';
import { selectCompanyHandler } from '../redux/dashboard/actions';
import { setUserCompanyData, setUserRoomsData } from '../redux/userCompany/action';
import LoginService from '../service/login';
import UserService from '../service/user';
import { setItem } from '../utility/localStorageControl';
import { ROLES } from '../utility/constants';

const loginService = new LoginService();
const userService = new UserService();

export default function UserSelect() {
  const { data: users, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: userService.getUsers,
  });
  const dispatch = useDispatch();
  const demoMode = useSelector(state => state.demoMode);

  const isSuperAdmin = useMemo(() => {
    const decoded = loginService.getDecodedToken();
    if (!decoded) {
      return false;
    }
    return decoded.payload?.role_id === 1;
  }, []);

  const isImpersonating = useMemo(() => {
    const decoded = loginService.getDecodedToken();
    if (!decoded) {
      return false;
    }
    return !!decoded.payload?.impersonatedBy;
  }, []);

  const scrambleUserEmail = useCallback((user, index) => {
    const userRole = ROLES[user.role_id];
    return `${userRole}${index}@company${(user.company_id + 9).toString(36).toUpperCase()}.com`;
  }, []);

  const selectOptions = useMemo(() => {
    if (!users?.length) {
      return [];
    }
    return users.map((u, i) => ({
      value: u.id,
      label: demoMode.enabled ? scrambleUserEmail(u, i) : u.email,
    }));
  }, [demoMode, scrambleUserEmail, users]);

  async function onUserSelect(user) {
    const newToken = await userService.impersonate(user);
    loginService.setToken(newToken);
    dispatch(login());
    const { payload: impersonatedUser } = loginService.getDecodedToken();
    if (impersonatedUser?.role_id >= 2) {
      try {
        const res = await userService.getUserCompany(impersonatedUser?.id);
        dispatch(
          selectCompanyHandler({ id: res?.data?.data?.id, name: res?.data?.data?.name, slug: res?.data?.data?.slug }),
        );
        if (impersonatedUser.role_id === 2) {
          dispatch(setUserCompanyData([]));
          dispatch(setUserRoomsData(res?.data?.count));
        } else {
          dispatch(setUserCompanyData(res?.data?.data?.company_sites));
          dispatch(setUserRoomsData(res?.data?.count));
        }
        setItem('user', res?.data?.data);
        window.location.reload();
      } catch (err) {
        console.log('Some error', err);
      }
    } else {
      dispatch(setUserCompanyData(''));
      window.location.reload();
    }
  }

  async function onStopImpersonating() {
    const newToken = await userService.stopImpersonating();
    loginService.setToken(newToken);
    dispatch(login());
    dispatch(setUserCompanyData(''));
    window.location.reload();
  }

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  if (isLoading || !users) {
    return null;
  }

  if (isImpersonating) {
    return <Button onClick={onStopImpersonating}>Stop impersonation</Button>;
  }

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <Select
      style={{
        width: '100%',
      }}
      className="user-select"
      onChange={onUserSelect}
      placeholder="Select a user to impersonate"
      showSearch
      filterOption={filterOption}
      optionFilterProp="children"
      options={selectOptions}
    />
  );
}

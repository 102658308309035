import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Input, Row, Select, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import LoginService from '../../../service/login';
import CultivarsService from '../../../service/cultivars';
import GrowersService from '../../../service/growers';
import OrchardsService from '../../../service/orchards';
import SitesService from '../../../service/sites';
import TreatmentsService from '../../../service/treatments';
import { getItem } from '../../../utility/localStorageControl';
import useFirstRender from '../../../hooks/useFirstRender';
import { selectFilterHandler } from '../../../redux/filter/actions';
import { selectOptionValues } from '../../../redux/selectOptions/actions';
import { defaultFilter } from '../../../redux/filter/reducers';
import { naturalSort } from '../../../utility/helpers';
import { HeaderWrapper } from '../../../components/dashboard-header/style';
import { optifluxColors } from '../../../layout/colors';
import { styleDrop } from '../../../components/dashboard-header';
import { formatRqValue } from '../../../components/dashboard-header/utils';
import FilterWithSearch from '../../../components/dashboard-header/filter-with-search';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const loginService = new LoginService();
const cultivarsService = new CultivarsService();
const growersService = new GrowersService();
const orchardsService = new OrchardsService();
const sitesService = new SitesService();
const treatmentsService = new TreatmentsService();

const HomeDashboardHeader = props => {
  const userData = getItem('user');
  const user = loginService.getDecodedToken();
  const globalStates = useSelector(state => state?.Dashboard);
  const filterState = useSelector(state => state.filter);
  const dateFormat = 'DD/MM/YYYY';
  const { toggleDashboardView, tableView, isMachineCycle, setSelectedContainer } = props;
  const [state, setState] = useState({
    harvest: filterState.harvest,
    harvest_date: filterState.harvest_date,
  });
  const [filters, setFilters] = useState(filterState);
  const [rqValue, setRqValue] = useState(formatRqValue(filterState?.RQ, filterState?.RQ_type));

  const [growers, setGrowers] = useState([]);
  const [cultivars, setCultivars] = useState([]);
  const [orchards, setOrchards] = useState([]);
  const [sites, setSites] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [growerIds, setGrowerIds] = useState([]);
  const [stateRQ, setStateRQ] = useState(filterState?.RQ_type || 'RQ');
  const dispatch = useDispatch();
  const isFirstRender = useFirstRender();
  const handleChange = (value, name) => {
    setFilters(st => ({
      ...st,
      [name]: value,
    }));
  };
  const handleTypeChange = (value, name) => {
    setState(st => ({
      ...st,
      [name]: value,
    }));

    if (value === '') {
      setFilters(st => ({
        ...st,
        [name]: value,
        harvest_date: '',
      }));
    }
  };
  useEffect(() => {
    dispatch(selectFilterHandler(filters));
  }, [filters]);
  useEffect(() => {
    cultivarsService
      .getCultivars()
      .then(response => {
        setCultivars(response.data.data);
        dispatch(selectOptionValues(response.data.data, 'cultivars'));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
    treatmentsService
      .getTreatments()
      .then(response => {
        setTreatments(response.data.data);
        dispatch(selectOptionValues(response.data.data, 'treatments'));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);
  useEffect(() => {
    sitesService
      .getSitesbyId(globalStates?.companyId ? { company_id: globalStates?.companyId } : {})
      .then(response => {
        const companySites = response.data.data;
        setSites(companySites);
        dispatch(selectOptionValues(response.data.data, 'sites'));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [globalStates?.companyId]);
  useEffect(() => {
    if (!isFirstRender) {
      setFilters(defaultFilter);
    }
  }, [globalStates.companyId]);
  useEffect(() => {
    growersService
      .getGrowers(globalStates?.companyId ? { company_id: globalStates?.companyId } : {})
      .then(response => {
        setGrowers(response.data.data);
        const growerId = response.data.data.map(x => x.id);
        setGrowerIds(growerId);
        dispatch(selectOptionValues(response.data.data, 'growers'));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [globalStates?.companyId]);
  useEffect(() => {
    orchardsService
      .getOrchards(growerIds.length > 0 ? { growers_id: growerIds } : {})
      .then(response => {
        setOrchards(response.data.data);
        dispatch(selectOptionValues(response.data.data, 'orchards'));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [growerIds]);
  const onChange = value => {
    setFilters(st => ({
      ...st,
      // eslint-disable-next-line no-underscore-dangle
      harvest_date: value === null ? '' : new Date(value._d).toISOString().split('T')[0],
      harvest: value === null ? '' : state.harvest,
    }));
  };
  const onChangeRange = value => {
    setFilters(st => ({
      ...st,
      // eslint-disable-next-line no-underscore-dangle
      harvest_date:
        value === null
          ? ''
          : `${new Date(value[0]._d).toISOString().split('T')[0]},${new Date(value[1]._d).toISOString().split('T')[0]}`,
      harvest: value === null ? '' : state.harvest,
    }));
  };
  const onChangeRQ = value => {
    setFilters(st => ({
      ...st,
      // eslint-disable-next-line no-underscore-dangle
      RQ_type: value === null ? '' : stateRQ,
      RQ: value === null ? '' : value,
    }));
  };
  const handleRangeChange = (e, value) => {
    if (value === 'min') {
      setRqValue(st => ({
        ...st,
        min: e,
      }));
    } else if (value === 'max') {
      setRqValue(st => ({
        ...st,
        max: e,
      }));
    }
  };
  const handleRangeRQ = () => {
    if (rqValue.min !== '' && rqValue.max !== '') {
      setFilters(st => ({
        ...st,
        RQ_type: 'range',
        RQ: `${rqValue.min},${rqValue.max}`,
      }));
    }
  };

  return (
    <>
      <HeaderWrapper>
        {!isMachineCycle && (
          <Row justify="end" className="main-row-header">
            <Col>
              <Tooltip title="Add content">
                <Button
                  shape="circle"
                  type="primary"
                  color={optifluxColors.darkGreen}
                  style={{ margin: '0.5rem 0' }}
                  onClick={() => setSelectedContainer({})}
                >
                  <PlusOutlined style={{ fontSize: '1rem', color: optifluxColors.whiteBackground }} />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={24}>
            <Row justify="space-between">
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <FilterWithSearch
                  onChange={e => {
                    handleChange(e, 'cultivar');
                  }}
                  value={filters.cultivar ? filters.cultivar : []}
                  placeholder="Cultivars"
                  options={cultivars.sort((a, b) => naturalSort(a.title, b.title))}
                  labelFormat={cultivar => cultivar.title}
                />
              </Col>
              {user?.payload?.role_id !== 4 && (
                <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                  <FilterWithSearch
                    onChange={e => {
                      handleChange(e, 'grower');
                    }}
                    value={filters.grower ? filters.grower : []}
                    placeholder="Growers"
                    options={growers.sort((a, b) => naturalSort(a.name, b.name))}
                  />
                </Col>
              )}
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <FilterWithSearch
                  onChange={e => {
                    handleChange(e, 'orchard');
                  }}
                  value={filters.orchard ? filters.orchard : []}
                  placeholder="Orchards"
                  options={orchards.sort((a, b) => naturalSort(a.name, b.name))}
                />
              </Col>
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                {user?.payload?.role_id === 3 || user?.payload?.role_id === 4 ? (
                  <Select
                    mode="multiple"
                    onChange={e => {
                      handleChange(e, 'site');
                    }}
                    value={filters.site ? filters.site : []}
                    bordered={false}
                    showSearch={false}
                    showArrow
                    dropdownStyle={styleDrop}
                    defaultValue={[]}
                    placeholder="Sites"
                  >
                    {userData?.company_sites
                      ?.sort((a, b) => naturalSort(a.name, b.name))
                      .map(site => {
                        return (
                          <Option key={`siteOption_${site.id}`} value={site.id}>
                            {site.name}
                          </Option>
                        );
                      })}
                  </Select>
                ) : (
                  <Select
                    mode="multiple"
                    onChange={e => {
                      handleChange(e, 'site');
                    }}
                    value={filters.site ? filters.site : []}
                    bordered={false}
                    showSearch={false}
                    showArrow
                    dropdownStyle={styleDrop}
                    defaultValue={[]}
                    placeholder="Sites"
                  >
                    {sites
                      ?.sort((a, b) => naturalSort(a.name, b.name))
                      .map(site => {
                        return (
                          <Option key={`siteMultiple_${site?.id}`} value={site.id}>
                            {site.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Col>
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <Select
                  mode="multiple"
                  onChange={e => {
                    handleChange(e, 'treatment');
                  }}
                  value={filters.treatment ? filters.treatment : []}
                  bordered={false}
                  showSearch={false}
                  showArrow
                  dropdownStyle={styleDrop}
                  defaultValue={[]}
                  placeholder="Treatments"
                >
                  {treatments
                    .sort((a, b) => naturalSort(a.title, b.title))
                    .map(treatment => {
                      return (
                        <Option key={`treatment_${treatment.id}`} value={treatment.id}>
                          {treatment.title}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                {/* <Select
                  mode="multiple"
                  onChange={e => {
                    handleChange(e, 'mode');
                  }}
                  value={filters.mode ? filters.mode : []}
                  bordered={false}
                  showSearch={false}
                  showArrow
                  dropdownStyle={styleDrop}
                  defaultValue={[]}
                  placeholder="Modes"
                >
                  <Option value="ulo">ULO</Option>
                  <Option value="dca">DCA</Option>
                </Select> */}
                <Select
                  defaultValue="RQ"
                  bordered={false}
                  value={stateRQ}
                  dropdownStyle={styleDrop}
                  onChange={e => {
                    setStateRQ(e);
                    setRqValue(e === 'range' ? { min: '', max: '' } : '');
                    setFilters({ ...filters, RQ_type: e, RQ: '' });
                  }}
                >
                  <Option value="RQ">Any RQ</Option>
                  {/* <Option value="specific">Specific</Option> */}
                  <Option value="before">Lower than</Option>
                  <Option value="after">Greater than</Option>
                  <Option value="range">Range</Option>
                </Select>
                {(stateRQ === 'before' || stateRQ === 'after' || stateRQ === 'specific' || stateRQ === 'range') && (
                  <div style={{ marginTop: '1rem' }}>
                    {(stateRQ === 'before' || stateRQ === 'after' || stateRQ === 'specific') && (
                      <Input
                        placeholder={filterState?.RQ || 'Enter value'}
                        onBlur={e => {
                          onChangeRQ(e.target.value);
                        }}
                        onPressEnter={e => {
                          onChangeRQ(e.target.value);
                        }}
                      />
                    )}
                    {stateRQ === 'range' && (
                      <Input.Group compact className="d-flex">
                        <Input
                          style={{ width: '100%', textAlign: 'center' }}
                          placeholder={rqValue?.min || 'Minimum'}
                          onChange={e => {
                            handleRangeChange(e.target.value, 'min');
                          }}
                          onBlur={() => {
                            handleRangeRQ();
                          }}
                          onPressEnter={() => {
                            handleRangeRQ();
                          }}
                        />

                        <Input
                          className="site-input-right mt-10"
                          style={{
                            width: '100%',
                            textAlign: 'center',
                          }}
                          placeholder={rqValue?.max || 'Maximum'}
                          onChange={e => {
                            handleRangeChange(e.target.value, 'max');
                          }}
                          onBlur={() => {
                            handleRangeRQ();
                          }}
                          onPressEnter={() => {
                            handleRangeRQ();
                          }}
                        />
                      </Input.Group>
                    )}
                  </div>
                )}
              </Col>{' '}
              <Col lg={3} xs={11} sm={7} md={7} className="pd-10">
                <Select
                  defaultValue={state.harvest ? state.harvest : ''}
                  bordered={false}
                  onChange={e => {
                    handleTypeChange(e, 'harvest');
                  }}
                >
                  <Option value="">All harvest date</Option>
                  <Option value="specific">Specific date</Option>
                  <Option value="before">Before</Option>
                  <Option value="after">After</Option>
                  <Option value="range">Range</Option>
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        {(state.harvest === 'before' ||
          state.harvest === 'after' ||
          state.harvest === 'specific' ||
          state.harvest === 'range') && (
          <Row justify="end">
            <Col lg={3} xs={24} sm={7} md={7} className="d-flex ">
              {(state.harvest === 'before' || state.harvest === 'after' || state.harvest === 'specific') && (
                <DatePicker
                  format={dateFormat}
                  onChange={onChange}
                  defaultValue={state.harvest_date ? moment(state.harvest_date) : null}
                />
              )}
              {state.harvest === 'range' && <RangePicker format={dateFormat} onChange={onChangeRange} />}
            </Col>
          </Row>
        )}
      </HeaderWrapper>
    </>
  );
};

export default HomeDashboardHeader;

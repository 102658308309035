export const formatRqValue = (value, type) => {
  if (type === 'range') {
    if (value) {
      const [min, max] = value.split(',');
      return { min, max };
    }
    return {
      min: '',
      max: '',
    };
  }
  return value;
};

export const storageInsightDisorders = [
  'Rotten',
  'Mold',
  'Core fungus',
  'Bitter pit',
  'Lenticel-rot',
  'Scald',
  'Greasiness',
  'Black stem',
  'Hollow',
  'Brown',
  'Core flush',
  'Fruit Flesh Brown',
  'Low temperature Decay',
  'Internal Core Brown',
  'Watercore',
];

import React from 'react';
import { Select } from 'antd';

const { Option } = Select;
const styleDrop = {
  border: '1px solid #d9d9d9',
  fontWeight: 600,
  textAlign: 'left',
  background: 'white',
  color: 'black',
};

const FilterWithSearch = ({ options, labelFormat, ...rest }) => {
  return (
    <Select
      mode="multiple"
      bordered={false}
      showArrow
      dropdownStyle={styleDrop}
      defaultValue={[]}
      optionFilterProp="label"
      showSearch
      className="green-filter"
      {...rest}
    >
      {options.map(option => {
        const label = labelFormat ? labelFormat(option) : option.name;
        return (
          <Option key={option.id} value={option.id} label={label}>
            {label}
          </Option>
        );
      })}
    </Select>
  );
};

export default FilterWithSearch;

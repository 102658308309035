import React, { useEffect, useMemo, useState } from 'react';
import { round } from 'lodash';
import { AlertOutlined, SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { Button, Divider, Popover, Progress, Tooltip, Typography, Badge, Space } from 'antd';
import LoginService from '../../service/login';
import {
  collectAlertsForBatch,
  dateSort,
  getGasFractionsWithSetColumns,
  getMetricState,
  naturalSort,
} from '../../utility/helpers';
import { Cards } from '../../components/cards/frame/cards-frame';
import Sliders from '../card-with-chart/slider';
import { roundString } from '../../utility/utility';
import { hueToDecimalColorScore } from '../storage-measurements/overview/combine-charts/hueToColors';
import { isDeviationExceeded } from '../watchdog-dashboard/utils';
import { optiControlKey, sections } from '../../utility/constants';

const { Text, Title } = Typography;

const styleDivider = {
  margin: '10px 0px',
};

const loginService = new LoginService();

const checkWatchdogAlerts = single => {
  const watchdogAlerts = single?.watchdog_alerts;
  const optifluxMeasurements = single?.room_measurements;
  const latestMeasurements = optifluxMeasurements?.length ? optifluxMeasurements[0] : null;
  const latestSensorValues = single?.room_sensors[0];
  let totalAlerts = 0;
  if (
    watchdogAlerts &&
    single?.active === true &&
    watchdogAlerts?.snooze == null &&
    watchdogAlerts?.deletedAt == null
  ) {
    if (
      (watchdogAlerts?.o2_min != null &&
        Number(latestMeasurements?.o2 / (10000).toFixed(2)) < Number(watchdogAlerts?.o2_min)) ||
      (watchdogAlerts?.o2_max != null &&
        Number(latestMeasurements?.o2 / (10000).toFixed(2)) > Number(watchdogAlerts?.o2_max))
    ) {
      totalAlerts += 1;
    } else if (
      (watchdogAlerts?.co2_min != null &&
        Number(latestMeasurements?.co2 / (10000).toFixed(2)) < Number(watchdogAlerts?.co2_min)) ||
      (watchdogAlerts?.co2_max != null &&
        Number(latestMeasurements?.co2 / (10000).toFixed(2)) > Number(watchdogAlerts?.co2_max))
    ) {
      totalAlerts += 1;
    } else if (
      (watchdogAlerts?.temperature_min != null &&
        Number(latestSensorValues?.temperature_pt100) < Number(watchdogAlerts?.temperature_min)) ||
      (watchdogAlerts?.temperature_max != null &&
        Number(latestSensorValues?.temperature_pt100) > Number(watchdogAlerts?.temperature_max))
    ) {
      totalAlerts += 1;
    } else if (
      (watchdogAlerts?.ethlyene_min != null &&
        Number(latestMeasurements?.c2h4) < Number(watchdogAlerts?.ethlyene_min)) ||
      (watchdogAlerts?.ethlyene_max != null && Number(latestMeasurements?.c2h4) > Number(watchdogAlerts?.ethlyene_max))
    ) {
      totalAlerts += 1;
    } else {
      // this alert might slow down the home dashboard, because then we need to include machine cycle measurement averages
      const o2Deviation = watchdogAlerts?.o2_deviation;
      const co2Deviation = watchdogAlerts?.co2_deviation;
      const temperatureDeviation = watchdogAlerts?.temperature_deviation;
      const o2Exceeded = isDeviationExceeded(o2Deviation, single?.avgO2, single?.avgExternalO2);
      const co2Exceeded = isDeviationExceeded(co2Deviation, single?.avgCO2, single?.avgExternalCO2);
      const temperatureExceeded = isDeviationExceeded(
        temperatureDeviation,
        single?.avgTemperature,
        single?.avgExternalTemperature,
        true,
      );
      if (o2Exceeded || co2Exceeded || temperatureExceeded) {
        totalAlerts += 1;
      }
    }
  }
  return totalAlerts;
};

const checkSiAlerts = room => {
  return room.room_contents.reduce((acc, curr) => {
    if (collectAlertsForBatch(curr)) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

const checkDcaAlerts = room => {
  if (room?.rooms_to_room_dca?.length) {
    return room?.rooms_to_room_dca[0]?.rq_valid === '0.00' ? 1 : 0;
  }
  return 0;
};

export const getAllAlertsPerRoom = room => {
  const numberOfAlerts = {
    [sections[optiControlKey].label]: checkWatchdogAlerts(room),
    [sections.storageInsights.label]: checkSiAlerts(room),
    [sections.DCA.label]: checkDcaAlerts(room),
  };
  return numberOfAlerts;
};

export function getHomeTableColumns(state, handleNextPage, setSelectedContainer, setAlertsInfo) {
  const user = loginService.getDecodedToken();
  const isMetric = getMetricState(user?.payload);
  return [
    {
      title: 'Room',
      dataIndex: 'name',
      key: 'room',
      sorter: (a, b) => naturalSort(a.name, b.name),
      render: (text, record) => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div
              className="cursor-pointer hover-on-cell"
              role="button"
              onClick={() => {
                handleNextPage('default', record);
              }}
              tabIndex="0"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleNextPage('default', record);
                }
              }}
            >
              {record.name}
            </div>
          ),
        };
      },
    },
    ...getGasFractionsWithSetColumns(handleNextPage, isMetric),
    {
      title: 'Ethylene [ppm]',
      dataIndex: 'id',
      key: 'ethylene',
      sorter: (a, b) => {
        const valueA = a.room_measurements[0]?.c2h4 ? a.room_measurements[0]?.c2h4 : '----';
        const valueB = b.room_measurements[0]?.c2h4 ? b.room_measurements[0]?.c2h4 : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div
              className={
                record.active === false
                  ? 'lightgrey'
                  : record?.watchdog_alerts?.ethlyene_max && record?.watchdog_alerts?.ethlyene_min
                  ? Number(record?.watchdog_alerts?.ethlyene_max) < Number(record?.room_measurements[0]?.c2h4) ||
                    Number(record?.watchdog_alerts?.ethlyene_min) > Number(record?.room_measurements[0]?.c2h4)
                    ? 'cursor-pointer hover-on-cell clr-red'
                    : 'cursor-pointer hover-on-cell '
                  : 'cursor-pointer hover-on-cell'
              }
              role="button"
              onClick={() => {
                handleNextPage('c2h4', record);
              }}
              tabIndex="-1"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleNextPage('c2h4', record);
                }
              }}
            >
              {record?.room_measurements[0]?.c2h4 ? record?.room_measurements[0]?.c2h4 : '----'}
            </div>
          ),
        };
      },
    },
    {
      title: 'RQ',
      dataIndex: 'id',
      key: 'RQ',
      sorter: (a, b) => {
        const valueA = a.room_dca_rq?.length && a.room_dca_rq[0]?.rq ? roundString(a.room_dca_rq[0]?.rq) : '----';
        const valueB = a.room_dca_rq?.length && b.room_dca_rq[0]?.rq ? roundString(b.room_dca_rq[0]?.rq) : '----';
        return naturalSort(valueA, valueB);
      },
      render: (text, record) => {
        return (
          <div
            className={
              record?.room_measurements.length && record?.room_measurements[0]?.dca_meas === 1
                ? 'cursor-pointer hover-on-cell rq-orange'
                : 'cursor-pointer hover-on-cell'
            }
            role="button"
            onClick={() => {
              handleNextPage('dca', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('dca', record);
              }
            }}
          >
            {record?.room_dca_rq?.length && record?.room_dca_rq[0]?.rq
              ? roundString(record?.room_dca_rq[0]?.rq, 2)
              : '----'}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          End of last RQ <br /> measurement
        </div>
      ),
      dataIndex: 'RQLast',
      key: 'RQLast',
      sorter: (a, b) => dateSort(a.room_dca_rq[0]?.datetime, b.room_dca_rq[0]?.datetime),
      render: (text, record) => {
        const lastRQ = record?.room_dca_rq[0]?.createdAt;
        const date = moment(lastRQ || '');
        const format = user.payload.datetimeFormat || 'DD/MM/YY HH:mm';
        return (
          <div
            className="cursor-pointer hover-on-cell"
            role="button"
            onClick={() => {
              handleNextPage('dca', record);
            }}
            tabIndex="-1"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('dca', record);
              }
            }}
          >
            {date.isValid() ? date.format(format) : '-----'}
          </div>
        );
      },
    },
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      key: 'lastUpdate',
      sorter: (a, b) => dateSort(a.room_measurements[0]?.updatedAt, b.room_measurements[0]?.updatedAt),
      render: (text, record) => {
        const date = moment(record?.room_measurements[0]?.updatedAt);
        const format = user?.payload?.datetimeFormat || 'DD/MM/YY HH:mm';
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div
              className="cursor-pointer hover-on-cell"
              role="button"
              onClick={() => {
                handleNextPage('default', record);
              }}
              tabIndex="-1"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleNextPage('default', record);
                }
              }}
            >
              {date.isValid() ? date.format(format) : '--/--/--'}
            </div>
          ),
        };
      },
    },
    // {
    //   title: 'Active',
    //   dataIndex: 'active',
    //   key: 'active',
    //   className: 'hide-in-pdf',
    //   render: (text, record) => {
    //     return {
    //       props: {
    //         style: { color: record.active ? '' : 'lightgrey' },
    //       },
    //       children: (
    //         <Checkbox
    //           checked={state.value === record.id ? state.checked : text}
    //           onChange={e => handleCheckbox(e, record.id)}
    //           disabled={user?.payload?.role_id === 4}
    //         />
    //       ),
    //     };
    //   },
    // },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      className: 'hide-in-pdf',
      render: record => {
        return {
          props: {
            style: { color: record.active ? '' : 'lightgrey' },
          },
          children: (
            <div className="table-actions popover-icons-table">
              <Tooltip title="Settings" placement="top">
                <Button
                  disabled={user?.payload?.role_id !== 1 && user?.payload?.role_id !== 2}
                  className="btn-icon"
                  onClick={() => setSelectedContainer(record)}
                  type="info"
                  to="#"
                  shape="circle"
                >
                  <SettingOutlined size={16} />
                </Button>
              </Tooltip>
            </div>
          ),
        };
      },
    },
    {
      title: 'Alerts',
      dataIndex: '',
      key: 'alerts',
      render: record => {
        const numberOfAlerts = getAllAlertsPerRoom(record);
        const totalNumberOfAlerts = Object.values(numberOfAlerts).reduce((acc, curr) => acc + curr, 0);
        const noAlerts = totalNumberOfAlerts === 0;
        return (
          <Space
            size="middle"
            onClick={() => setAlertsInfo({ name: record.name, numberOfAlerts })}
            className="cursor-pointer"
          >
            <Badge size="small" count={totalNumberOfAlerts} showZero status={noAlerts ? 'success' : 'warning'}>
              <AlertOutlined style={{ fontSize: '16px' }} />
            </Badge>
          </Space>
        );
      },
    },
  ];
}

export const HomeCardWithChart = ({ data, handleNextPage }) => {
  const {
    active,
    room_settings: roomSettingsProp,
    room_measurements: roomMeasurements,
    room_sensors: roomSensors,
    site,
    name,
    roomContents,
  } = data;
  const [disableCard, setDisableCard] = useState(false);
  const handleStyle = {
    borderColor: 'red',
  };
  const { payload: userData } = useMemo(() => loginService.getDecodedToken(), []);
  const isMetric = useMemo(() => getMetricState(userData), [userData]);

  useEffect(() => {
    setDisableCard(!active);
  }, [active]);

  const roomSettings = useMemo(() => {
    if (!roomSettingsProp?.length) {
      return null;
    }
    return roomSettingsProp[0];
  }, [roomSettingsProp]);

  const roomLatestMeasurement = useMemo(() => {
    if (!roomMeasurements?.length) {
      return null;
    }
    return roomMeasurements[0];
  }, [roomMeasurements]);

  const o2SliderMarks = useMemo(() => {
    if (!roomSettings || !roomLatestMeasurement) {
      return 0;
    }
    if (roomSettings.o2_setpoint) {
      const key = Number(roomSettings.o2_setpoint);
      return { [key]: roomSettings.o2_setpoint };
    }
    if (roomLatestMeasurement.o2_set) {
      const key = Number(roomLatestMeasurement.o2_set);
      return { [key]: roomLatestMeasurement.o2_set };
    }

    return 0;
  }, [roomLatestMeasurement, roomSettings]);

  const co2SliderMarks = useMemo(() => {
    if (!roomSettings || !roomLatestMeasurement) {
      return 0;
    }
    if (roomSettings.co2_setpoint) {
      const key = Number(roomSettings.co2_setpoint);
      return { [key]: roomSettings.co2_setpoint };
    }
    if (roomLatestMeasurement.co2_set) {
      const key = Number(roomLatestMeasurement.co2_set);
      return { [key]: roomLatestMeasurement.co2_set };
    }

    return 0;
  }, [roomLatestMeasurement, roomSettings]);

  const formattedDate = useMemo(() => {
    if (!roomMeasurements.length && !roomSensors?.length) {
      return 'No data';
    }
    const userFormat = userData.datetimeFormat;
    const lastMeas = roomSensors?.length ? roomSensors[0].createdAt : roomMeasurements[0].createdAt;
    const date = moment(lastMeas);
    return date.isValid() ? date.format(userFormat) : '--/--/--';
  }, [data, userData.datetimeFormat]);

  const trackStyle = {
    backgroundColor: 'red',
  };

  const trackGreen = {
    backgroundColor: '#00CA95',
  };

  return (
    <>
      <Cards
        isbutton={
          <div className="card-nav custom-modal-logs- cards-dashboard cursor-pointer">
            <Popover
              placement="rightTop"
              content={
                <>
                  <div>
                    <Text strong>Site : </Text>
                    <Text>{site?.name}</Text>
                  </div>
                  <div>
                    <Text strong>Room : </Text>
                    <Text>{name}</Text>
                  </div>
                  <Divider style={styleDivider} />
                  {roomContents?.map(single => (
                    <div key={single.id}>
                      <div>
                        <Text strong>Cultivar : </Text>
                        <Text>{single?.cultivar?.title}</Text>
                      </div>
                      <div>
                        <Text strong>Grower : </Text>
                        <Text>{single?.grower?.name}</Text>
                      </div>
                      <div>
                        <Text strong>Orchard : </Text>
                        <Text>{single?.orchard?.name}</Text>
                      </div>
                      <div>
                        <Text strong>Treatment : </Text>
                        {single?.room_content_to_room_content_treatment?.map((row, index) => (
                          <Text key={index}>
                            {(index ? '/ ' : '') + row?.room_content_treatment_to_treatment?.title}{' '}
                          </Text>
                        ))}
                      </div>
                      <Divider style={styleDivider} />
                    </div>
                  ))}
                </>
              }
            >
              <FeatherIcon icon="info" size={16} />
            </Popover>
          </div>
        }
        title={data?.name}
      >
        <div className="text-center">
          <Progress
            className="cursor-pointer"
            type="circle"
            width="200px"
            onClick={() => {
              handleNextPage('temprature', data);
            }}
            format={() => {
              return (
                <div>
                  <div>
                    <Title
                      level={1}
                      disabled={disableCard}
                      style={disableCard ? { color: 'rgba(0, 0, 0, 0.25)' } : { color: data?.color }}
                    >
                      {data?.room_sensors[0]?.temperature_pt100 ? data?.room_sensors[0]?.temperature_pt100 : '--'}
                      <sup className="degree-icon" style={{ marginRight: '1px' }}>
                        o
                      </sup>
                      {isMetric ? 'C' : 'F'}
                      <sub
                        style={{
                          fontSize: '14px',
                          color: disableCard ? '#dfe0fd' : '#000',
                        }}
                      >
                        {data?.room_measurements_set[0]?.temperature_set
                          ? data?.room_measurements_set[0]?.temperature_set
                          : ''}
                      </sub>
                    </Title>
                  </div>
                  <div>
                    <Text type="secondary" disabled={disableCard}>
                      Temperature
                    </Text>
                  </div>
                </div>
              );
            }}
            percent={99.99}
            strokeWidth={1}
            strokeColor={
              disableCard
                ? '#dfe0fd'
                : data?.active === true && data?.watchdog_alerts?.snooze == null
                ? data?.watchdog_alerts?.temperature_max && data?.watchdog_alerts?.temperature_min
                  ? Number(data?.watchdog_alerts?.temperature_max) <=
                      Number(data?.room_sensors[0]?.temperature_pt100) ||
                    Number(data?.watchdog_alerts?.temperature_min) >= Number(data?.room_sensors[0]?.temperature_pt100)
                    ? 'red'
                    : '#dfe0fd'
                  : '#dfe0fd'
                : '#dfe0fd'
            }
          />
        </div>
        <div className="text-center mt-10">
          <Text type="secondary" disabled={disableCard}>
            Last update
          </Text>
          <Text strong className="ml-5" disabled={disableCard}>
            {formattedDate}
          </Text>
        </div>
        <div role="button" tabIndex="0" className="cursor-pointer" onClick={() => handleNextPage('default', data)}>
          <Sliders
            max={
              data?.room_measurements[0]?.o2
                ? (data?.room_measurements[0]?.o2 / 10000).toFixed(2) > 25
                  ? (data?.room_measurements[0]?.o2 / 10000).toFixed(2)
                  : 25
                : 25
            }
            min={0}
            maxLabel={25}
            defaultValue={data?.room_measurements[0]?.o2 ? (data?.room_measurements[0]?.o2 / 10000).toFixed(2) : '--'}
            labelSub={2}
            label="O"
            color="#00ca95"
            disableCard={disableCard}
            marks={o2SliderMarks}
            handleStyle={
              data?.active === true && data?.watchdog_alerts?.snooze == null
                ? data?.watchdog_alerts?.o2_max && data?.watchdog_alerts?.o2_min
                  ? Number(data?.watchdog_alerts?.o2_max) <=
                      Number(data?.room_measurements[0]?.o2 / (10000).toFixed(2)) ||
                    Number(data?.watchdog_alerts?.o2_min) >= Number(data?.room_measurements[0]?.o2 / (10000).toFixed(2))
                    ? handleStyle
                    : ''
                  : ''
                : ''
            }
            trackStyle={
              data?.active === true && data?.watchdog_alerts?.snooze == null
                ? data?.watchdog_alerts?.o2_max && data?.watchdog_alerts?.o2_min
                  ? Number(data?.watchdog_alerts?.o2_max) <=
                      Number(data?.room_measurements[0]?.o2 / (10000).toFixed(2)) ||
                    Number(data?.watchdog_alerts?.o2_min) >= Number(data?.room_measurements[0]?.o2 / (10000).toFixed(2))
                    ? trackStyle
                    : trackGreen
                  : trackGreen
                : trackGreen
            }
          />
        </div>
        <div role="button" tabIndex="0" className="cursor-pointer" onClick={() => handleNextPage('default', data)}>
          <Sliders
            max={
              data?.room_measurements[0]?.co2
                ? (data?.room_measurements[0]?.co2 / 10000).toFixed(2) > 3
                  ? (data?.room_measurements[0]?.co2 / 10000).toFixed(2)
                  : 3
                : 3
            }
            min={0}
            maxLabel={3}
            defaultValue={data?.room_measurements[0]?.co2 ? (data?.room_measurements[0]?.co2 / 10000).toFixed(2) : '--'}
            labelSub={2}
            label="CO"
            color={data?.color}
            disableCard={disableCard}
            marks={co2SliderMarks}
            handleStyle={
              data?.active === true && data?.watchdog_alerts?.snooze == null
                ? data?.watchdog_alerts?.co2_max && data?.watchdog_alerts?.co2_min
                  ? Number(data?.watchdog_alerts?.co2_max) <=
                      Number(data?.room_measurements[0]?.co2 / (10000).toFixed(2)) ||
                    Number(data?.watchdog_alerts?.co2_min) >=
                      Number(data?.room_measurements[0]?.co2 / (10000).toFixed(2))
                    ? handleStyle
                    : ''
                  : ''
                : ''
            }
            trackStyle={
              data?.active === true && data?.watchdog_alerts?.snooze == null
                ? data?.watchdog_alerts?.co2_max && data?.watchdog_alerts?.co2_min
                  ? Number(data?.watchdog_alerts?.co2_max) <=
                      Number(data?.room_measurements[0]?.co2 / (10000).toFixed(2)) ||
                    Number(data?.watchdog_alerts?.co2_min) >=
                      Number(data?.room_measurements[0]?.co2 / (10000).toFixed(2))
                    ? trackStyle
                    : trackGreen
                  : trackGreen
                : trackGreen
            }
          />
        </div>
        <div role="button" tabIndex="0" className="cursor-pointer" onClick={() => handleNextPage('ethlene', data)}>
          <Sliders
            max={
              data?.room_measurements[0]?.c2h4
                ? data?.room_measurements[0]?.c2h4 > 100
                  ? data?.room_measurements[0]?.c2h4
                  : 100
                : 100
            }
            maxLabel={100}
            min={
              data?.room_measurements[0]?.c2h4
                ? data?.room_measurements[0]?.c2h4 > 0
                  ? 0
                  : data?.room_measurements[0]?.c2h4 < 0
                  ? -100
                  : 0
                : 0
            }
            defaultValue={data?.room_measurements[0]?.c2h4 ? data?.room_measurements[0]?.c2h4 : -100}
            label="Ethylene"
            color="#00ca95"
            marks={{}}
            disableCard={disableCard}
            handleStyle={
              data?.active === true && data?.watchdog_alerts?.snooze == null
                ? data?.watchdog_alerts?.ethlyene_max && data?.watchdog_alerts?.ethlyene_min
                  ? Number(data?.watchdog_alerts?.ethlyene_max) <= Number(data?.room_measurements[0]?.c2h4) ||
                    Number(data?.watchdog_alerts?.ethlyene_min) >= Number(data?.room_measurements[0]?.c2h4)
                    ? handleStyle
                    : ''
                  : ''
                : ''
            }
            trackStyle={
              data?.active === true && data?.watchdog_alerts?.snooze == null
                ? data?.watchdog_alerts?.ethlyene_max && data?.watchdog_alerts?.ethlyene_min
                  ? Number(data?.watchdog_alerts?.ethlyene_max) <= Number(data?.room_measurements[0]?.c2h4) ||
                    Number(data?.watchdog_alerts?.ethlyene_min) >= Number(data?.room_measurements[0]?.c2h4)
                    ? trackStyle
                    : trackGreen
                  : trackGreen
                : trackGreen
            }
          />
        </div>
      </Cards>
    </>
  );
};

export const homeDashboardInnerTableColumns = handleNextPage => {
  const user = loginService.getDecodedToken();
  return [
    {
      title: 'Grower',
      dataIndex: ['grower', 'name'],
      key: 'grower',
    },
    {
      title: 'Orchard',
      dataIndex: ['orchard', 'name'],
      key: 'orchard',
    },
    {
      title: 'Cultivar',
      dataIndex: ['cultivar', 'title'],
      key: 'cultivar',
    },
    {
      title: 'Firmness',
      key: 'firmness',
      render: (_, record) => (
        <span
          className="cursor-pointer hover-on-cell"
          tabIndex="0"
          role="button"
          onKeyUp={e => {
            if (e.key === 'Enter') {
              handleNextPage('si', record);
            }
          }}
          onClick={() => handleNextPage('si', record)}
        >
          {round(record?.simulations?.length ? record.simulations[0].mean : record?.latestFirmness, 2) || '----'}
        </span>
      ),
    },
    {
      title: 'Color',
      key: 'color',
      render: (_, record) => {
        const colorValue = record?.simulations?.length ? record.simulations[1].mean : record?.latestColor;
        return (
          <span
            className="cursor-pointer hover-on-cell"
            tabIndex="0"
            role="button"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNextPage('si', record);
              }
            }}
            onClick={() => handleNextPage('si', record)}
          >
            {round(hueToDecimalColorScore(colorValue), 2) || '----'}
          </span>
        );
      },
    },
    {
      title: 'Treatment',
      dataIndex: ['treatment', 'title'],
      key: 'treatment',
      render: (text, record) => {
        return (
          <>
            {record?.room_content_to_room_content_treatment?.map(single => (
              <div key={`treatmentRoom_${single?.id}`}>{single?.room_content_treatment_to_treatment?.title}</div>
            ))}
          </>
        );
      },
    },
    {
      title: 'Palox',
      dataIndex: 'palox',
      key: 'palox',
    },
    {
      title: "Palox's Amount",
      dataIndex: 'amount',
      key: 'paloxamount',
    },
    {
      title: 'Harvest',
      dataIndex: 'harvest',
      key: 'harvest',
      render: text => {
        const date = moment(text);
        const format = user?.payload?.dateFormat || 'DD/MM/YY HH:mm';
        return date.isValid() ? date.format(format) : '--/--/--';
      },
    },
  ];
};
